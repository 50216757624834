import Documentation from "../../components/Documentation";
import classes from "./Mint.module.css";
import {Col, Container, FormControl, Row, InputGroup, Button, Spinner} from "react-bootstrap";
import {LoadingNoun} from "../../components/Noun";
import Banner from "../../components/Banner";
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import auctionActivityClasses from "../../components/AuctionActivity/AuctionActivity.module.css";
import bidClasses from "../../components/Bid/Bid.module.css";
import {Link} from "react-router-dom";
import bidBtnClasses from "../../components/BidHistoryBtn/BidHistoryBtn.module.css";
import {Trans} from "@lingui/macro";
import {NounbitsTokenFactory} from "@nouns/sdk";
import config from "../../config";
import {useContractFunction} from "@usedapp/core";
import { utils } from 'ethers';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {AlertModal, setAlertModal} from "../../state/slices/application";

const MintPage = () => {
    const activeAccount = useAppSelector(state => state.account.activeAccount);

    const nounbitsTokenContract = new NounbitsTokenFactory().attach(
        config.addresses.nounbitsToken,
    );

    const [bidInput, setBidInput] = useState('');

    const bidInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        let localQuantity: number = +event.target.value;
        if (localQuantity >= 0 && localQuantity <= 20) {
            setBidInput(event.target.value);
        }
    };

    const { send: placeBid, state: placeBidState } = useContractFunction(
        nounbitsTokenContract,
        'mint',
    );

    const placeBidHandler = async () => {
        let input: number = +bidInput;
        const amount = input * 0.035;
        const value = utils.parseEther(amount.toString());

        placeBid(bidInput, {
            value,
        });
    };

    const [bidButtonContent, setBidButtonContent] = useState({
        loading: false,
        content: <Trans>Place bid</Trans>,
    });

    const dispatch = useAppDispatch();
    const setModal = useCallback((modal: AlertModal) => dispatch(setAlertModal(modal)), [dispatch]);

    // placing bid transaction state hook
    useEffect(() => {
        switch (placeBidState.status) {
            case 'None':
                setBidButtonContent({
                    loading: false,
                    content: <Trans>Mint</Trans>,
                });
                break;
            case 'Mining':
                setBidButtonContent({ loading: true, content: <></> });
                break;
            case 'Success':
                setModal({
                    title: <Trans>Success</Trans>,
                    message: <Trans>Mint successful!</Trans>,
                    show: true,
                });
                setBidButtonContent({ loading: false, content: <Trans>Mint</Trans> });
                break;
            case 'Fail':
                setModal({
                    title: <Trans>Transaction Failed</Trans>,
                    message: placeBidState?.errorMessage || <Trans>Please try again.</Trans>,
                    show: true,
                });
                setBidButtonContent({ loading: false, content: <Trans>Mint</Trans> });
                break;
            case 'Exception':
                setModal({
                    title: <Trans>Error</Trans>,
                    message: placeBidState?.errorMessage || <Trans>Please try again.</Trans>,
                    show: true,
                });
                setBidButtonContent({ loading: false, content: <Trans>Mint</Trans> });
                break;
        }
    }, [placeBidState, setModal]);

    const isDisabled = placeBidState.status === 'Mining' || !activeAccount;

    return (
        <>
            <div style={{ backgroundColor: '#d5d7e1' }} className={classes.wrapper}>
                <Container fluid="xl">
                    <Row className={auctionActivityClasses.activityRow}>
                        <Col lg={{ span: 6 }}>
                            <LoadingNoun />
                        </Col>
                        <Col lg={{ span: 6 }} className={classes.mintWrapper}>
                            <h1 style={{ color: 'var(--brand-cool-dark-text)' }}>
                                Nounbits
                            </h1>
                            <InputGroup>
                                <FormControl
                                    className={bidClasses.bidInput}
                                    placeholder='1-20'
                                    type="number"
                                    min="0"
                                    max="20"
                                    onChange={bidInputHandler}
                                    value={bidInput}
                                />
                                <Button
                                    className={bidClasses.bidBtn}
                                    onClick={placeBidHandler}
                                    disabled={isDisabled}
                                >
                                    {bidButtonContent.loading ? <Spinner animation="border" /> : bidButtonContent.content}
                                </Button>
                            </InputGroup>

                            <div className={classes.bidCollection}>
                                {+bidInput > 0 && +bidInput <= 20 ? (
                                    <p><b>Price</b>: {(+bidInput * 0.035).toFixed(3)}Ξ</p>
                                ) : (
                                    <>
                                        <p>0.035Ξ per Nounbit<br/>20 per wallet<br/>10k supply</p>
                                    </>
                                )}
                            </div>

                            <Link to="/playground" className={bidBtnClasses.bidHistoryCool}>
                                <Trans>Playground</Trans> →
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Banner />
            <Documentation />
        </>
    );
};

export default MintPage;
