import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';
import { Trans } from '@lingui/macro';

const Documentation = () => {
  const playgroundLink = (
    <Link
      text="Playground"
      url="/playground"
      leavesPage={false}
    />
  );
  const publicDomainLink = (
    <Link
      text="public domain"
      url="https://creativecommons.org/publicdomain/zero/1.0/"
      leavesPage={true}
    />
  );
  const compoundGovLink = (
    <Link
      text="Compound Governance"
      url="https://compound.finance/governance"
      leavesPage={true}
    />
  );
  const nounsLink = (
    <Link
      text="nouns.wtf"
      url="https://nouns.wtf"
      leavesPage={true}
    />
  );
  return (
    <Section fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>
            <Trans>XYZ?</Trans>
          </h1>
          <p className={classes.aboutText}>
            <Trans>
              Nounbits are on-chain, full bodied nouns. The project was created to support Nouns and other extension
              projects monetarily. Mint proceeds to be divided as follows: 60% to Nouns DAO treasury, 30% to support
              Noun extension projects, 10% to Nounbits founders.
            </Trans>
          </p>
          <p className={classes.aboutText} style={{ paddingBottom: '4rem' }}>
            Early minters of Nounbits will have the opportunity to enjoy an on-chain reveal and choose extension
            projects to support. Traits and possible combinations can be found in the {playgroundLink}.
          </p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Summary</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Nounbits artwork is in the {publicDomainLink}
                </li>
                <li>
                  <Trans>ERC721a, on-chain art, and reveal</Trans>
                </li>
                <li>
                  <Trans>0.035Ξ per Nounbit, 20 per wallet, 10k supply</Trans>
                </li>
                <li>
                  <Trans>60% of Nounbit mint proceeds trustlessly sent to the Nouns treasury</Trans>
                </li>
                <li>
                  <Trans>30% mint proceeds used to support Noun extension projects</Trans>
                </li>
                <li>
                  <Trans>Artwork is generative and stored directly on-chain (not IPFS)</Trans>
                </li>
                <li>
                  <Trans>No explicit rules exist for attribute scarcity; all Nounbits are equally rare</Trans>
                </li>
                <li>
                  <Trans>Nounbiters receive rewards in the form of 10% of mint proceeds</Trans>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Nouns DAO</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Nouns DAO utilizes a fork of {compoundGovLink} and is the main governing body of the
                Nouns ecosystem. The Nouns DAO treasury receives 100% of ETH proceeds from daily
                Noun auctions. Each Noun is an irrevocable member of Nouns DAO and entitled to one
                vote in all governance matters. Noun votes are non-transferable (if you sell your
                Noun the vote goes with it) but delegatable, which means you can assign your vote to
                someone else as long as you own your Noun.
              </p>
              <p>
                Learn more at {nounsLink}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Nounbit Traits</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  Nounbits are generated randomly based on an Ethereum block hash. There are no 'if' statements or other
                  rules governing Nounbit trait scarcity, which makes all Nounbits equally rare. As of this writing,
                  Nounbits are made up of:
                </Trans>
              </p>
              <ul>
                <li>
                  <Trans>backgrounds (2)</Trans>
                </li>
                <li>
                  <Trans>bodies (30)</Trans>
                </li>
                <li>
                  <Trans>accessories (137)</Trans>
                </li>
                <li>
                  <Trans>heads (234)</Trans>
                </li>
                <li>
                  <Trans>glasses (21)</Trans>
                </li>
                <li>
                  <Trans>pants (42)</Trans>
                </li>
                <li>
                  <Trans>shoes (21)</Trans>
                </li>
              </ul>
              You can experiment with off-chain Nounbit generation at the {playgroundLink}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Nounbit Seeder</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  The Nounbit Seeder contract is used to determine Nounbit traits upon completion of the reveal process.
                  This differs from the Noun seeder contract in that the seeder cannot be replaced in the future and the
                  actual seed is not stored on the token. Nounbit seeds are a derived value on the token using a
                  combination of the tokenId and the pseudorandomHash stored on the Token contract:
                </Trans>
              </p>
              <code>INounsSeeder.Seed memory seed = seeder.generateSeed(tokenId, descriptor, pseudorandomHash);</code>
              <br/>
              <br/>
              <p>
                <Trans>
                  Reimagining the Noun seeder contract allows Nounbits to create an on-chain reveal and lower the gas
                  price on mint. Since the seed is not stored on the token, this removes the need to assign a seed value
                  for each token being minted.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Wen Reveal?</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  The reveal date will be <b><u>6/19/2022</u></b> or upon completion of the mint, whichever
                  occurs first. If all Nounbits are not minted by the reveal date, the seed of a Nounbit will be
                  predictable using a combination of the pseudo-random hash on the token and the next token ID.
                </Trans>
              </p>
              <p>
                <Trans>
                  With the Nounbit Seeder handling seed generation differently, you will see a placeholder Nounbit
                  on mint. The reveal will occur when the Nounbits team calls <code>setPseudorandomHash</code> on
                  the Token contract. The team will then need to lock the hash, descriptor, and seeder on the Token
                  contract and parts on the Descriptor contract. Locking all these pieces is necessary to maintain
                  a consistent seed through the existence of a Nounbit.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>On-Chain Artwork</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  Nounbits are stored directly on Ethereum and do not utilize pointers to other
                  networks such as IPFS. This is possible because Nounbit parts are compressed and
                  stored on-chain using a custom run-length encoding (RLE), which is a form of
                  lossless compression.
                </Trans>
              </p>
              <p>
                <Trans>
                  The compressed parts are efficiently converted into a single base64 encoded SVG
                  image on-chain. To accomplish this, each part is decoded into an intermediate
                  format before being converted into a series of SVG rects using batched, on-chain
                  string concatenation. Once the entire SVG has been generated, it is base64
                  encoded.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
