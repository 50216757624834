import classes from './Banner.module.css';
import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import world_noun from '../../assets/banner.png';
import Image from 'react-bootstrap/Image';
import { Trans } from '@lingui/macro';

const Banner = () => {
  return (
    <Section fullWidth={false} className={classes.bannerSection}>
      <Col lg={6}>
        <div className={classes.wrapper}>
          <h1>
            <Trans>10k NOUNBITS,</Trans>
            <br />
            <Trans>NO MORE,</Trans>
            <br />
            <Trans>NO LESS.</Trans>
          </h1>
        </div>
      </Col>
      <Col lg={6}>
        <div>
          <Image src={world_noun} alt="noun" fluid />
        </div>
      </Col>
    </Section>
  );
};

export default Banner;
