export enum ExternalURL {
  discord,
  twitter,
  notion,
  discourse,
  nouns,
  etherscan,
  opensea,
}

export const externalURL = (externalURL: ExternalURL) => {
  switch (externalURL) {
    case ExternalURL.discord:
      return 'http://discord.gg/nouns';
    case ExternalURL.twitter:
      return 'https://twitter.com/nounbits';
    case ExternalURL.notion:
      return 'https://nouns.notion.site/Explore-Nouns-a2a9dceeb1d54e10b9cbf3f931c2266f';
    case ExternalURL.discourse:
      return 'https://discourse.nouns.wtf/';
    case ExternalURL.nouns:
      return 'https://nouns.wtf/';
    case ExternalURL.etherscan:
      return 'https://etherscan.io/address/0xe8ec221c53a3ecf1db23f9731184df65170a254b';
    case ExternalURL.opensea:
      return 'https://opensea.io/collection/nounbits-nft';
  }
};
